export const LOGIN_ROUTE = '/login';
export const SIGNUP_ROUTE = '/signup';
export const SIGNUP_CUSTOMER_ROUTE = '/signup-customer';
export const SIGNUP_TYPE_ROUTE = '/signup-type';
export const VENDOR_SEARCH_ROUTE = '/vendor-search';
export const QUEUE_START_ROUTE = '/manage-queue';
export const CUS_QUEUE_ROUTE = '/cus-queue';
export const MAIN_ROUTE= '/';
export const ADMIN_LOGIN_ROUTE = '/admin-login';
export const ADMIN_HOME_ROUTE = '/admin-home';
export const MANAGE_VENDOR_ROUTE = '/manage-vendors';
export const MANAGE_BRANCHES_ROUTE = '/manage-branches';
export const MANAGE_CUSTOMER_ROUTE = '/manage-customers';
