import { styled, Box } from '@mui/material';

export const StyledMainContentDiv = styled('div', {
  shouldForwardProp: (prop: any) => prop !== 'open' && prop !== 'noSideNav',
})<{ open?: boolean, noSideNav?: boolean }>(({ open, noSideNav }) => ({
  marginLeft: noSideNav ? "0px": open ? '245px' : '144px',
  height: '100%'
}));

export const StyledContentBox = styled(Box, {
  shouldForwardProp: (prop: any) => prop !== 'logged',
})<{ logged?: boolean}>(({logged}) => ({
  flexGrow: 1,
  height: logged ? '80vh':'100vh',
  overflowY: 'auto'
}));


