import { Box, Button, Checkbox, MenuItem, Select, TextField, Typography } from "@mui/material";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import FieldContainer from "../FieldContainer/field-container";
import FormContainer from "../FormContainer/form-container";
import { INVALID_EMAIL_MSG, MAX_TEXTAREA_LENGTH, MAX_TEXTAREA_LENGTH_MSG, REQUIRED_MSG, TRAILING_SPACES_MSG, defaultVendorAccount } from "../../constants/common";
import AlertBox from "../Alert/alert-box";
import { useNavigate } from "react-router-dom";
import { LOGIN_ROUTE } from "../../constants/routes";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import './style.css';
import logo from "../../assets/logo-white-bg.png";
import { createVendorAccount } from "../../store/vendor/vendorAction";
import { ICreateVendorAccount } from "../../typings/interfaces/ICreateVendorAccount";
import { getVendorCategory } from "../../store/category/categoryAction";
import { ICat } from "../../typings/interfaces/ICatState";
import DialogBox from "../Dialog/dialog-box";
import Disclaimer from "./disclaimer";

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .required(REQUIRED_MSG)
    .trim(TRAILING_SPACES_MSG)
    .email(INVALID_EMAIL_MSG)
    .matches(
      RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/),
      INVALID_EMAIL_MSG
    ),
  password: Yup.string().required(REQUIRED_MSG),
  name: Yup.string().max(MAX_TEXTAREA_LENGTH, MAX_TEXTAREA_LENGTH_MSG).required(REQUIRED_MSG),
  vendor_category_id: Yup.number().min(1).required(REQUIRED_MSG),
  createdby: Yup.string().max(MAX_TEXTAREA_LENGTH, MAX_TEXTAREA_LENGTH_MSG).required(REQUIRED_MSG),
  contact_no: Yup.string().required(REQUIRED_MSG),
  disclaimerread: Yup.boolean(),
});

const Signup = () => {

  const [value, setValue] = useState<ICreateVendorAccount>(defaultVendorAccount);
  const vendor = useAppSelector((state) => state.vendor)
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const catList = useAppSelector((state) => state.category);
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleCreateVendorAccount = (value: ICreateVendorAccount) => {debugger
    value && dispatch(createVendorAccount(value));
  };

  useEffect(()=>{
    dispatch(getVendorCategory());
  },[])

  return (
      <Box className="boxstly" sx={{ display: "flex", marginLeft: "10%", alignContent: "center",  width: "80%", height:"auto"}}>
        <DialogBox
          title="Disclaimer for Q-Wait"
          content={<Disclaimer />}
          handleClose={() => setDialogOpen(false)}
          openDialog={dialogOpen}
          buttonText="I agree"
        />
        <Box className="form" sx={{ width: "500px", margin: "auto"}}>
            <div><img className="logo" src={logo} width={50} alt="Q-Wait logo"/></div>
            <Typography variant="h6" sx={{color:"#8C3C99", fontWeight:"700", marginTop: "4%", marginLeft: "40%", marginBottom: "4%", alignContent: "center"}}>
                Create Account
            </Typography>
                  
            <FormContainer className="form" formTitle="" sx={{border: "none"}}>
               
              <Formik
                initialValues={value}
                validationSchema={loginSchema}
                onSubmit={handleCreateVendorAccount}
              >
                {(props) => (
                  <Form>
                    <FieldContainer label="Email*" sx={{marginBottom:3}}>
                      <TextField
                        name="email"
                        size="small"
                        placeholder={"Email"}
                        fullWidth
                        value={props.values.email}
                        error={props.errors.email === undefined ? false : true}
                        helperText={props.errors.email}
                        onChange={(e) => {
                          props.setFieldValue(e.target.name, e.target.value);
                        }}
                      />
                    </FieldContainer>
                    <FieldContainer label="Password*">
                      <TextField
                        name="password"
                        type="password"
                        size="small"
                        placeholder={"Password"}
                        fullWidth
                        value={props.values.password}
                        error={props.errors.password === undefined ? false : true}
                        helperText={props.errors.password}
                        onChange={(e) => {
                          props.setFieldValue(e.target.name, e.target.value);
                        }}
                      />
                    </FieldContainer>
                    <FieldContainer label="Vendor Name*">
                      <TextField
                        name="name"
                        type="text"
                        size="small"
                        placeholder={"Vendor"}
                        fullWidth
                        value={props.values.name}
                        error={props.errors.name === undefined ? false : true}
                        helperText={props.errors.name}
                        onChange={(e) => {
                          props.setFieldValue(e.target.name, e.target.value);
                        }}
                      />
                    </FieldContainer>
                    <FieldContainer label="Category*">
                      <Select
                        name="vendor_category_id"
                        size="small"
                        fullWidth
                        value={props.values.vendor_category_id}
                        error={props.errors.vendor_category_id === undefined ? false : true}
                        onChange={(e) => {
                          props.setFieldValue(e.target.name, e.target.value);
                        }}
                      >
                        <MenuItem value="" disabled>
                          Select Category
                        </MenuItem>
                        {catList && catList.catResponse && catList.catResponse.map((cat: ICat) => {
                          return <MenuItem key={cat.id} value={cat.id}>
                            {cat.category_name}
                          </MenuItem>
                        })}
                      </Select>
                    </FieldContainer>
                    <FieldContainer label="Your Name*">
                      <TextField
                        name="createdby"
                        type="text"
                        size="small"
                        placeholder={"createdby"}
                        fullWidth
                        value={props.values.createdby}
                        error={props.errors.createdby === undefined ? false : true}
                        helperText={props.errors.createdby}
                        onChange={(e) => {
                          props.setFieldValue(e.target.name, e.target.value);
                        }}
                      />
                    </FieldContainer>
                    <FieldContainer label="Contact No*">
                      <TextField
                        name="contact_no"
                        type="text"
                        size="small"
                        placeholder={"contact_no"}
                        fullWidth
                        value={props.values.contact_no}
                        error={props.errors.contact_no === undefined ? false : true}
                        helperText={props.errors.contact_no}
                        onChange={(e) => {
                          props.setFieldValue(e.target.name, e.target.value);
                        }}
                      />
                    </FieldContainer>
                    <FieldContainer label="City">
                      <TextField
                        name="city"
                        type="text"
                        size="small"
                        placeholder={"city"}
                        fullWidth
                        value={props.values.city}
                        error={props.errors.city === undefined ? false : true}
                        helperText={props.errors.city}
                        onChange={(e) => {
                          props.setFieldValue(e.target.name, e.target.value);
                        }}
                      />
                    </FieldContainer>
                    <FieldContainer label="Village">
                      <TextField
                        name="village"
                        type="text"
                        size="small"
                        placeholder={"village"}
                        fullWidth
                        value={props.values.village}
                        error={props.errors.village === undefined ? false : true}
                        helperText={props.errors.village}
                        onChange={(e) => {
                          props.setFieldValue(e.target.name, e.target.value);
                        }}
                      />
                    </FieldContainer>
                    <FieldContainer label="Province">
                      <TextField
                        name="province"
                        type="text"
                        size="small"
                        placeholder={"province"}
                        fullWidth
                        value={props.values.province}
                        error={props.errors.province === undefined ? false : true}
                        helperText={props.errors.province}
                        onChange={(e) => {
                          props.setFieldValue(e.target.name, e.target.value);
                        }}
                      />
                    </FieldContainer>
                    <FieldContainer label="I have read and understood">
                      <Checkbox name="disclaimerread" checked={props.values.disclaimerread} onChange={(e) => {
                          props.setFieldValue(e.target.name, e.target.checked);
                        }}/>
                      <Button variant="text" onClick={()=>setDialogOpen(true)}>
                        View Disclaimer
                      </Button>
                    </FieldContainer>
                    <Button disabled={!props.values.disclaimerread} sx={{background:"#8C3C99", width:"200px", marginRight:"20px"}} variant="contained"  type="submit" >
                      Create Account
                    </Button>
                    <Button onClick={() => navigate(LOGIN_ROUTE)} sx={{background:"#fff", color:"#8C3C99", '&:hover': {
                        color: '#fff',
                      }, width:"200px", border:"1px solid #8C3C99"}} 
                      variant="contained"  type="submit" >
                      Go back
                    </Button>
                  </Form>
                )}
              </Formik>
              <FieldContainer label="">
              {vendor.createVendorError && <AlertBox message={vendor.createVendorError.response.data.error} severity="error" showAlert /> } 
              </FieldContainer>
              <FieldContainer label="">
              {vendor.createVendorResponse && <AlertBox message={vendor.createVendorResponse.message} severity="success" showAlert /> } 
              </FieldContainer>
            </FormContainer>
            
          

        </Box>
        <Box className="purple" sx={{ backgroundColor: "#CF9DD7", width: "650px", overflow:"hidden" }}>
          <div className="image">
            <img className="banner" src={require('../../assets/queue1.png')} />
          </div>
        </Box>
      </Box>

  );
};

export default Signup;
