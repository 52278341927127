import { Box, Button, Typography } from "@mui/material";
import CustomerType  from '../../assets/customertype.png';
import VendorType from '../../assets/vendortype.png';
import { useNavigate } from "react-router-dom";
import { MANAGE_CUSTOMER_ROUTE, MANAGE_VENDOR_ROUTE } from "../../constants/routes";

const AdminHome = () => {
  const imgBaseUrl = process.env.REACT_APP_IMG_BASE_URL;
  const navigate = useNavigate();
  return (
    <Box display={"flex"} justifyContent={"center"} gap={5} sx={{height: "80vh"}}>
      <Box
        sx={{
          backgroundColor: "#c59dcb",
          height: "270px",
          padding: "20px",
          borderRadius: "10px",
          boxShadow: "0px 21px 41px -16px rgba(77,60,77,1)",
          marginTop: "50px",
          width: "250px"
        }}
      >
        <Typography
          variant="h5"
          sx={{ marginY: "20px", textAlign: "center" }}
        >{`Manage Vendors`}</Typography>
        <Box display={"grid"} flexDirection={"row"} gap={4}>
          <Box className="heroSectionImgContainer">
            <img
              src={`${VendorType}`}
              alt=""
              style={{ borderRadius: "50%", border: "5px solid #8c3c99" }}
              className="heroSectionImg"
            />
          </Box>
          <Button variant="contained" onClick={()=> navigate(MANAGE_VENDOR_ROUTE)}>Go To</Button>
        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: "#c59dcb",
          height: "270px",
          padding: "20px",
          borderRadius: "10px",
          boxShadow: "0px 21px 41px -16px rgba(77,60,77,1)",
          marginTop: "50px",
          width: "250px"
        }}
      >
        <Typography
          variant="h5"
          sx={{ marginY: "20px", textAlign: "center"}}
        >{`Manage Customers`}</Typography>
        <Box flexDirection={"row"} gap={4} display={"grid"}>
          <Box className="heroSectionImgContainer">
            <img
              src={`${CustomerType}`}
              alt=""
              style={{ borderRadius: "50%", border: "5px solid #8c3c99" }}
              className="heroSectionImg"
            />
          </Box>
          <Button variant="contained" onClick={()=> navigate(MANAGE_CUSTOMER_ROUTE)}>Go To</Button>
        </Box>
      </Box>
    </Box>
  );
};

export default AdminHome;
