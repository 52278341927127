import { createSlice } from "@reduxjs/toolkit";
import { IVendorBranchState } from "../../typings/interfaces/IVendorState";
import { createVendorAccount, findBranchedByVendor, getAllVendors, getQueueByQueueNo, getVendorBranch, getVendorsAllReservations, saveVendorBranch, updateVendor } from "./vendorAction";

const initialState: IVendorBranchState = {
    venResponse : null,
    venResponseLoading: false,
    venResponseError: undefined,

    venAllReservationsResponse: 0,
    venAllReservationsLoading: false,
    venAllReservationsError: undefined,

    currentServingNoResponse: null,
    currentServingNoLoading: false,
    currentServingNoError: undefined,

    createVendorResponse: null,
    createVendorLoading: false,
    createVendorError: undefined,

    venListResponse: null ,
    venListResponseLoading: false,
    venListResponseError: undefined,

    updateVendorResponse: null,
    updateVendorLoading: false,
    updateVendorError: undefined,

    branchResponse : null,
    branchResponseLoading: false,
    branchResponseError: undefined,

    createBranchResponse: null,
    createBranchLoading: false,
    createBranchError: undefined,
}

export const vendorSlice = createSlice({
    name: "vendor",
    initialState,
    reducers: {},
    extraReducers: (builder) =>{
          builder.addCase(getVendorBranch.pending, (state) => {
            state.venResponseLoading = true;
            state.venResponse = null;
            state.venResponseError = undefined;
          });
          builder.addCase(getVendorBranch.fulfilled, (state, action) => {
            state.venResponse = action.payload;
            state.venResponseError = undefined;
            state.venResponseLoading = false;
          });
          builder.addCase(getVendorBranch.rejected, (state, action) => {
            state.venResponseError = action.payload as any;
            state.venResponseLoading = false;
          });

          builder.addCase(getVendorsAllReservations.pending, (state) => {
            state.venAllReservationsLoading = true;
            state.venAllReservationsResponse = null;
            state.venAllReservationsError = undefined;
          });
          builder.addCase(getVendorsAllReservations.fulfilled, (state, action) => {
            state.venAllReservationsResponse = action.payload;
            state.venAllReservationsError = undefined;
            state.venAllReservationsLoading = false;
          });
          builder.addCase(getVendorsAllReservations.rejected, (state, action) => {
            state.venAllReservationsError = action.payload as any;
            state.venAllReservationsLoading = false;
          });

          builder.addCase(getQueueByQueueNo.pending, (state) => {
            state.currentServingNoLoading = true;
            state.currentServingNoResponse = null;
            state.currentServingNoError = undefined;
          });
          builder.addCase(getQueueByQueueNo.fulfilled, (state, action) => {
            state.currentServingNoResponse = action.payload;
            state.currentServingNoError = undefined;
            state.currentServingNoLoading = false;
          });
          builder.addCase(getQueueByQueueNo.rejected, (state, action) => {
            state.currentServingNoError = action.payload as any;
            state.currentServingNoLoading = false;
          });

          builder.addCase(createVendorAccount.pending, (state, action) =>{
            state.createVendorLoading = true;
            state.createVendorResponse = null;
            state.createVendorError = undefined;
          });
          builder.addCase(createVendorAccount.fulfilled, (state, action) =>{
            state.createVendorResponse = action.payload;
            state.createVendorError = undefined;
            state.createVendorLoading = false;
          });
          builder.addCase(createVendorAccount.rejected, (state, action) =>{
            state.createVendorError = action.payload as any;
            state.createVendorLoading = false;
          });

          builder.addCase(getAllVendors.pending, (state) => {
            state.venListResponseLoading = true;
            state.venListResponse = null;
            state.venListResponseError = undefined;
          });
          builder.addCase(getAllVendors.fulfilled, (state, action) => {
            state.venListResponse = action.payload;
            state.venListResponseError = undefined;
            state.venListResponseLoading = false;
          });
          builder.addCase(getAllVendors.rejected, (state, action) => {
            state.venListResponseError = action.payload as any;
            state.venListResponseLoading = false;
          });

          builder.addCase(updateVendor.pending, (state, action) =>{
            state.updateVendorLoading = true;
            state.updateVendorResponse = null;
            state.updateVendorError = undefined;
          });
          builder.addCase(updateVendor.fulfilled, (state, action) =>{
            state.updateVendorResponse = action.payload;
            state.updateVendorError = undefined;
            state.updateVendorLoading = false;
          });
          builder.addCase(updateVendor.rejected, (state, action) =>{
            state.updateVendorError = action.payload as any;
            state.updateVendorLoading = false;
          });

          builder.addCase(findBranchedByVendor.pending, (state) => {
            state.branchResponseLoading = true;
            state.branchResponse = null;
            state.branchResponseError = undefined;
          });
          builder.addCase(findBranchedByVendor.fulfilled, (state, action) => {
            state.branchResponse = action.payload;
            state.branchResponseError = undefined;
            state.branchResponseLoading = false;
          });
          builder.addCase(findBranchedByVendor.rejected, (state, action) => {
            state.branchResponseError = action.payload as any;
            state.branchResponseLoading = false;
          });

          builder.addCase(saveVendorBranch.pending, (state, action) =>{
            state.createBranchLoading = true;
            state.createBranchResponse = null;
            state.createBranchError = undefined;
          });
          builder.addCase(saveVendorBranch.fulfilled, (state, action) =>{
            state.createBranchResponse = action.payload;
            state.createBranchError = undefined;
            state.createBranchLoading = false;
          });
          builder.addCase(saveVendorBranch.rejected, (state, action) =>{
            state.createBranchError = action.payload as any;
            state.createBranchLoading = false;
          });

    }
})

export default vendorSlice.reducer;