import { lazy, Suspense } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import Login from "../components/Pages/login";
import { CUS_QUEUE_ROUTE, LOGIN_ROUTE, QUEUE_START_ROUTE, SIGNUP_TYPE_ROUTE, SIGNUP_ROUTE, VENDOR_SEARCH_ROUTE, SIGNUP_CUSTOMER_ROUTE, MAIN_ROUTE, ADMIN_LOGIN_ROUTE, ADMIN_HOME_ROUTE, MANAGE_VENDOR_ROUTE, MANAGE_CUSTOMER_ROUTE, MANAGE_BRANCHES_ROUTE } from "../constants/routes";
import VendorHome from "../components/Pages/vendor-home";
import CusHome from "../components/Pages/cus-home";
import CusQueue from "../components/Pages/cus-queue";
import Signup from "../components/Pages/signup";
import SignUpType from "../components/Pages/signup-type";
import SignupCustomer from "../components/Pages/signup-customer";
import Main from "../components/Pages/main";
import AdminLogin from "../components/Pages/adminlogin";
import AdminHome from "../components/Pages/adminhome";
import ManageVendors from "../components/Pages/managevendors";
import ManageCustomers from "../components/Pages/managecustomers";
import ManageBranches from "../components/Pages/managebranches";

export const APP_ROUTES = [
  // {
  //   path: "*",
  //   component: <NotFound />,
  // },
  {
    path : MAIN_ROUTE,
    component: <Main />,
  },
  {
    path: LOGIN_ROUTE,
    component: <Login />,
  },
  {
    path: SIGNUP_ROUTE,
    component: <Signup />,
  },
  {
    path: VENDOR_SEARCH_ROUTE,
    component: <CusHome />
  },
  {
    path: QUEUE_START_ROUTE,
    component: <VendorHome />
  },
  {
    path: `${CUS_QUEUE_ROUTE}/:id`,
    component: <CusQueue />
  },
  {
    path: SIGNUP_TYPE_ROUTE,
    component: <SignUpType />
  },
  {
    path: SIGNUP_CUSTOMER_ROUTE,
    component: <SignupCustomer />
  },
  {
    path: ADMIN_LOGIN_ROUTE,
    component: <AdminLogin />
  },
  {
    path: ADMIN_HOME_ROUTE,
    component: <AdminHome />
  },
  {
    path: MANAGE_VENDOR_ROUTE,
    component: <ManageVendors />
  },
  {
    path: MANAGE_CUSTOMER_ROUTE,
    component: <ManageCustomers />
  },
  {
    path: `${MANAGE_BRANCHES_ROUTE}/:id`,
    component: <ManageBranches />
  }


//{
  //   path: '/project/:id/:name/:client',
  //   component : <ProjectDetailView />
  // }
];

const AppRoutes = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        {APP_ROUTES.map((route, index) => (
          <Route key={index} element={route.component} path={route.path} />
        ))}
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
