import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import DataTable from '../DataTable/data-table';
import FormContainer from '../FormContainer/form-container';
import { Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { useEffect } from 'react';
import { getAllVendors, updateVendor } from '../../store/vendor/vendorAction';
import FieldContainer from '../FieldContainer/field-container';
import AlertBox from '../Alert/alert-box';
import { useNavigate } from 'react-router-dom';
import { MANAGE_BRANCHES_ROUTE } from '../../constants/routes';

const ManageVendors = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const vendor = useAppSelector(state => state.vendor);

    const handleVendorUpdate = (vendor: any) =>{
        vendor && dispatch(updateVendor({
            id: vendor.id,
            status: vendor.status
        }));
    }

    const columns: GridColDef[] = [
        {
        field: "vendor_name",
        headerName: "Vendor Name",
        sortable: false,
        disableColumnMenu: true,
        width: 200,
        valueGetter: (params: GridValueGetterParams) => {
            if (params.row.vendor_name == null) {
            return "-";
            }
            return `${params.row.vendor_name}`;
        },
        },

        {
        field: "category.category_name",
        headerName: "Category",
        sortable: false,
        disableColumnMenu: true,
        width: 200,
        valueGetter: (params: GridValueGetterParams) => {
            if (params.row.category == null || params.row.category.category_name == null) {
            return "-";
            }
            return `${params.row.category.category_name}`;
            //return new Date().toUTCString();
        },
        },
        {
        field: "email",
        headerName: "Email",
        sortable: false,
        disableColumnMenu: true,
        width: 250,
        valueGetter: (params: GridValueGetterParams) => {
            if (params.row.email == null) {
            return "-";
            }
            return `${params.row.email}`;
        },
        },
        {
        field: "contact",
        headerName: "Contact",
        sortable: false,
        disableColumnMenu: true,
        width: 150,
        valueGetter: (params: GridValueGetterParams) => {
            if (params.row.contact == null) {
            return "-";
            }
            return `${params.row.contact}`;
        }
        }
    ];

    const columnsWithAction = [
        ...columns,
        {
        field: "activate",
        headerName: "Activation",
        sortable: false,
        disableColumnMenu: true,
        width: 140,
        renderCell: (params: any) => {
            
            return (
            <>
                {<Button
                variant="contained"
                color={params.row.status === 1 ? "warning" :"success"}
                //disabled={editable || importCostingAllShipment.loading}
                onClick={() => {
                    handleVendorUpdate({
                        id: params.row.id,
                        status: params.row.status === 1 ? 0 : 1
                    })
                }}
                >
                    {params.row.status === 1 ? `Deactivate` : `Activate`}
                </Button>
                }
            </>
            );
        },
        },
        {
        field: "edit",
        headerName: "Edit",
        sortable: false,
        disableColumnMenu: true,
        width: 140,
        renderCell: (params: any) => (
            <Button
            variant="contained"
            color="primary"
            //disabled={importCostingAllShipment.loading}
            onClick={() => {
                // setEditRequest(true);
                // setSelectedShipment(params.row.id);
            }}
            >
            Edit
            </Button>
        ),
        },
        {
        field: "branches",
        headerName: "Branches",
        sortable: false,
        disableColumnMenu: true,
        width: 140,
        renderCell: (params: any) => (
            <Button
            variant="contained"
            color="secondary"
            //   disabled={importCostingAllShipment.loading}
            onClick={() => {
                navigate(`${MANAGE_BRANCHES_ROUTE}/${params.row.id}`);
            }}
            >
            View Branches
            </Button>
        ),
        },
    ];

    useEffect(()=>{
        dispatch(getAllVendors());
    },[])

    useEffect(()=>{
        dispatch(getAllVendors());
    },[vendor.updateVendorResponse])

  return (
    <>
      <FormContainer
        formTitle="Manage Vendors"
        sx={{ width: "1300px", border: "none" }}
      >
        <FieldContainer label="">
        {vendor.updateVendorError && <AlertBox message={vendor.updateVendorError.response.data.error} severity="error" showAlert /> } 
        </FieldContainer>
        <FieldContainer label="">
        {vendor.updateVendorResponse && <AlertBox message={vendor.updateVendorResponse.message} severity="success" showAlert /> } 
        </FieldContainer>
        <DataTable
          columns={columnsWithAction}
          loading={vendor.venListResponseLoading}
          rows={vendor.venListResponse || []}
          //hidePagination={true}
        />
      </FormContainer>
    </>
  );
};

export default ManageVendors;
