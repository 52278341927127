import { AlertBoxProps } from "../components/Alert/alert-box";

export const COMPANY_NAME = "QueueManagers";
export const MAX_TEXTAREA_LENGTH = 50;
export const DEFAULT_PAGE_SIZE = 12;

export const defaultAlertValue: AlertBoxProps = {
  message: "",
  severity: "info",
  showAlert: false,
};

export const defaultVendorAccount = {
  city: "",
  contact_no: "",
  country: "",
  createdby: "",
  disclaimerread: false,
  email: "",
  id: 0,
  name: "",
  password: "",
  profilepic: "",
  province: "",
  role: 0,
  village: "",
  vendor_category_id: 0,
};

export const defaultCustomerAccount = {
  contact_no: "",
  nic: "",
  disclaimerread: false,
  email: "",
  id: 0,
  name: "",
  password: "",
  profilepic: "",
  role: 2,
};

export const defaultVendor = {
  id: 0,
  vendor_name : ""
}

export const defaultVendorBranch = {
  id: 0,
  branch_name : "",
  email : "",
  description : "",
  image: "",
  duration: 0,
  vendor: defaultVendor,
  status: 0,
}


//error, success messages
export const REQUIRED_MSG = "Required";
export const TRAILING_SPACES_MSG =
  "Email cannot include leading and trailing spaces";
export const INVALID_EMAIL_MSG = "Not a proper email address";
export const MAX_TEXTAREA_LENGTH_MSG = `This field should be less than ${MAX_TEXTAREA_LENGTH}`;
export const SUCCESS_TRANSACTION_MSG = "Transaction completed successfully!";
export const ABORT_TRANSACTION_MSG = "No changes made! Operation abort.";
