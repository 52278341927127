import { Box, Button, Grid, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { useEffect, useState } from "react";
import {
  getQueueByQueueNo,
  getVendorsAllReservations,
} from "../../store/vendor/vendorAction";
import { updateQueueStatus } from "../../store/queue/queueAction";

const VendorHome = () => {
  const imgBaseUrl = process.env.REACT_APP_IMG_BASE_URL;
  const { authResponse } = useAppSelector((state) => state.auth);
  const { venAllReservationsResponse, currentServingNoResponse } =
    useAppSelector((state) => state.vendor);
  const queue = useAppSelector((state) => state.queue);
  const [queueStart, setQueueStart] = useState(false);
  const dispatch = useAppDispatch();

  const handleQueueStart = () => {
    setQueueStart(true);
    dispatch(
      getQueueByQueueNo({
        vendorId: authResponse.branch_id,
        qno: 1,
        checkAlreadyStarted: true,
      })
    );
  };

  const handleSkip = () => {
    setQueueStart(true);
    dispatch(updateQueueStatus({ queue: currentServingNoResponse, status: 3 }));
  };

  const handleBack = () => {
    authResponse &&
    currentServingNoResponse &&
    currentServingNoResponse.queueno > 0
      ? dispatch(
          getQueueByQueueNo({
            vendorId: authResponse.branch_id,
            qno: currentServingNoResponse.queueno - 1,
          })
        )
      : dispatch(
          getQueueByQueueNo({
            vendorId: authResponse.branch_id,
            qno: venAllReservationsResponse,
          })
        );
  };

  const handleNext = () => {
    setQueueStart(true);
    dispatch(updateQueueStatus({ queue: currentServingNoResponse, status: 2 }));
  };

  useEffect(() => {
    authResponse && dispatch(getVendorsAllReservations(authResponse.branch_id));
  }, []);

  useEffect(() => {
    queue.queueStatusResponse &&
      currentServingNoResponse &&
      dispatch(
        getQueueByQueueNo({
          vendorId: authResponse.branch_id,
          qno: currentServingNoResponse.queueno + 1,
        })
      );
  }, [queue.queueStatusResponse]);

  return (
    <>
      <Grid
        container
        sx={{ minHeight: "80vh", maxHeight: "calc(100vh-250px)" }}
        justifyContent={"center"}
        alignItems={"center"}
      >
        {!currentServingNoResponse && !queueStart && (
          <>
            <Box
              sx={{
                backgroundColor: "#c59dcb",
                minHeight: "150px",
                padding: "20px",
                borderRadius: "10px",
                boxShadow: "0px 21px 41px -16px rgba(77,60,77,1)",
                marginTop: "10px",
              }}
            >
              <Typography variant="h5" sx={{ marginY: "20px" }}>
                {venAllReservationsResponse ? `` : `No Clients!`}
              </Typography>
              <Box display={"flex"} flexDirection={"row"} gap={4}>
                <Box className="heroSectionImgContainer">
                  <img
                    src={`${imgBaseUrl}defaultVendor.png`}
                    alt=""
                    style={{ borderRadius: "50%", border: "5px solid #8c3c99" }}
                    className="heroSectionImg"
                  />
                </Box>
                <Box sx={{ textAlign: "center" }}>
                  <Typography variant="h6">Currently your</Typography>
                  <Typography variant="h6">queue length is</Typography>
                  <Typography variant="h3">
                    {venAllReservationsResponse || 0}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box sx={{ minHeight: "120px", padding: "20px" }}>
              <Button
                variant="contained"
                onClick={handleQueueStart}
                fullWidth
                disabled={venAllReservationsResponse === null}
                sx={{
                  height: "80px",
                  minWidth: "120px",
                  maxWidth: "280px",
                  backgroundColor: "#8c3c99",
                }}
              >
                <Typography variant="h6">Start Queue</Typography>
              </Button>
            </Box>
          </>
        )}
        {currentServingNoResponse && (
          <>
            {/* <Box sx={{ display: "flex"}}>
              <Typography>{currentServingNoResponse.queueno.toString() === "0" && "No more customers"}</Typography>
            </Box>
           */}
            <Box sx={{ minHeight: "120px", padding: "20px" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleBack}
                fullWidth
                disabled={currentServingNoResponse.queueno === 1}
                sx={{
                  height: "80px",
                  minWidth: "240px",
                  maxWidth: "280px",
                  border: "4px solid #8c3c99",
                  backgroundColor: "#58bad8",
                  borderRadius: "15px",
                  marginY: "20px",
                }}
              >
                <Typography variant="h5">Back</Typography>
              </Button>
            </Box>
            <Box
              sx={{
                backgroundColor: "#c59dcb",
                minHeight: "150px",
                padding: "20px",
                borderRadius: "10px",
                boxShadow: "0px 21px 41px -16px rgba(77,60,77,1)",
                marginTop: "10px",
              }}
            >
              {" "}
              {currentServingNoResponse.queueno.toString() === "0" ? (
                <Typography
                  variant="h4"
                  sx={{
                    marginY: "20px",
                    textAlign: "center",
                    fontWeight: "bold",
                    width: "230px",
                    height: "330px",
                    alignContent: "center"
                  }}
                >
                  No more customers
                </Typography>
              ) : (
                <>
                  <Typography
                    variant="h4"
                    sx={{
                      marginY: "20px",
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    {currentServingNoResponse.status.toString() === "2"
                      ? "Served"
                      : currentServingNoResponse.status.toString() === "3"
                      ? "Skipped"
                      : currentServingNoResponse.status.toString() === "4"
                      ? "Now Serving"
                      : ""}
                  </Typography>
                  <Box gap={4}>
                    <Box
                      className="heroSectionImgContainer"
                      sx={{ textAlign: "center", padding: "10px" }}
                    >
                      <Box
                        className="heroSectionImg"
                        style={{
                          display: "flex",
                          borderRadius: "50%",
                          border: "5px solid #fff",
                          backgroundColor: "#8c3c99",
                          justifyContent: "center",
                          alignItems: "center",
                          color: "#fff",
                        }}
                      >
                        <Typography variant="h3">
                          {currentServingNoResponse.queueno > 0
                            ? currentServingNoResponse.queueno
                            : "--"}
                        </Typography>
                      </Box>
                    </Box>

                    {currentServingNoResponse.user && (
                      <Box
                        sx={{
                          textAlign: "center",
                          backgroundColor: "#a86cb1",
                          borderRadius: "10px",
                          padding: "20px",
                          marginX: "20px",
                        }}
                      >
                        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                          {currentServingNoResponse.user.name}
                        </Typography>
                        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                          {currentServingNoResponse.user.nic}
                        </Typography>
                        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                          {currentServingNoResponse.user.contact}
                        </Typography>
                      </Box>
                    )}
                  </Box>{" "}
                </>
              )}
            </Box>
            <Box sx={{ minHeight: "120px", padding: "20px" }}>
              <Button
                variant="contained"
                color="error"
                onClick={handleSkip}
                disabled={currentServingNoResponse.queueno.toString() === "0"}
                fullWidth
                sx={{
                  height: "80px",
                  minWidth: "120px",
                  maxWidth: "280px",
                  border: "4px solid #8c3c99",
                  borderRadius: "15px",
                  marginY: "20px",
                }}
              >
                <Typography variant="h5">Skip</Typography>
              </Button>
              <Button
                variant="contained"
                color="success"
                onClick={handleNext}
                disabled={currentServingNoResponse.queueno.toString() === "0"}
                fullWidth
                sx={{
                  height: "80px",
                  minWidth: "120px",
                  maxWidth: "280px",
                  border: "4px solid #8c3c99",
                  borderRadius: "15px",
                  marginY: "20px",
                }}
              >
                <Typography variant="h5">Next</Typography>
              </Button>
            </Box>
          </>
        )}
      </Grid>
    </>
  );
};

export default VendorHome;
