import { createAsyncThunk } from "@reduxjs/toolkit";
import { FindBranchedByVendorAPI, GetAllVendorsAPI, GetQueueByQueueNoAPI, GetVendorsAllReservationsAPI, RegisterVendorAPI, SaveVendorBranchAPI, SearchVendorAPI, UpdateVendorAPI, UpdateVendorBranchAPI } from "../../service/apiMethods";

export const getVendorBranch = createAsyncThunk(
  "vendor/getVendorBranch",
  async (query: any, { rejectWithValue, getState }) => {
    try {
      const res = await SearchVendorAPI(query);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getVendorsAllReservations = createAsyncThunk(
  "vendor/getVendorsAllReservations",
  async (vendorId: any, { rejectWithValue, getState }) => {
    try {
      const res = await GetVendorsAllReservationsAPI(vendorId);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
)


export const getQueueByQueueNo = createAsyncThunk(
  "vendor/getQueueByQueueNo",
  async (request: any, { rejectWithValue, getState }) => {
    try {
      const res = await GetQueueByQueueNoAPI(request.vendorId, request.qno, request.checkAlreadyStarted);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
)

export const createVendorAccount = createAsyncThunk(
  "vendor/createVendorAccount",
  async (request: any, { rejectWithValue, getState }) =>{
    try {
      const res = await RegisterVendorAPI(request);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
)

export const getAllVendors = createAsyncThunk(
  "vendor/getAllVendors",
  async (__, { rejectWithValue, getState }) => {
    try {
      const res = await GetAllVendorsAPI();
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
)

export const updateVendor = createAsyncThunk(
  "vendor/updateVendor",
  async (request: any, { rejectWithValue, getState }) =>{
    try {
      const res = await UpdateVendorAPI(request, request.id);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
)


export const findBranchedByVendor = createAsyncThunk(
  "vendor/findBranchedByVendor",
  async (request: any, { rejectWithValue, getState }) => {
    try {
      const res = await FindBranchedByVendorAPI(request.id, request.page);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
)

export const saveVendorBranch = createAsyncThunk(
  "vendor/saveVendor",
  async (request: any, { rejectWithValue, getState }) =>{
    try {
      let res;
      if(request.id > 0){
        res = await UpdateVendorBranchAPI(request, request.id);
      }else{
        res = await SaveVendorBranchAPI(request);
      }
      
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
)