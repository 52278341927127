import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import DataTable from '../DataTable/data-table';
import FormContainer from '../FormContainer/form-container';
import { Box, Button, Grid, TextField } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { useEffect, useState } from 'react';
import { findBranchedByVendor, getAllVendors, saveVendorBranch, updateVendor } from '../../store/vendor/vendorAction';
import FieldContainer from '../FieldContainer/field-container';
import { useNavigate, useParams } from 'react-router-dom';
import { IPaginationModel } from '../../typings/interfaces/IPaginationModel';
import { MANAGE_VENDOR_ROUTE } from '../../constants/routes';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Form, Formik } from 'formik';
import { IVendorBranch } from '../../typings/interfaces/IVendorState';
import { REQUIRED_MSG, defaultVendorBranch } from '../../constants/common';
import * as Yup from "yup";
import AlertBox from '../Alert/alert-box';

const branchSchema = Yup.object().shape({
    branch_name: Yup.string().required(REQUIRED_MSG),
    description: Yup.string(),
    email: Yup.string(),
    duration: Yup.number(),
    vendor_id: Yup.number().min(1).required(REQUIRED_MSG)
});

const ManageBranches = () => {
    const { id } = useParams();
    const vendorId = id ? parseInt(id) : 0;
    const dispatch = useAppDispatch();
    const vendor = useAppSelector(state => state.vendor);
    const navigate = useNavigate();
    const [value, setValue] = useState<IVendorBranch>({...defaultVendorBranch, vendor_id: vendorId});

    const [paginationModel, setPaginationModel] = useState<IPaginationModel>({
        pageSize: 50,
        page: 1,
    });

    const handleVendorUpdate = (vendor: any) =>{
        vendor && dispatch(updateVendor({
            id: vendor.id,
            status: vendor.status
        }));
    }

    const handleCreateVendorBranch = (branch: any) =>{
        branch && dispatch(saveVendorBranch(branch));
    }

    const columns: GridColDef[] = [
        {
        field: "branch_name",
        headerName: "Branch Name",
        sortable: false,
        disableColumnMenu: true,
        width: 200,
        valueGetter: (params: GridValueGetterParams) => {
            if (params.row.branch_name == null) {
            return "-";
            }
            return `${params.row.branch_name}`;
        },
        },
        {
        field: "description",
        headerName: "Duration",
        sortable: false,
        disableColumnMenu: true,
        width: 200,
        valueGetter: (params: GridValueGetterParams) => {
            if (params.row.description == null) {
            return "-";
            }
            return `${params.row.description}`;
            //return new Date().toUTCString();
        },
        },
        {
        field: "email",
        headerName: "Email",
        sortable: false,
        disableColumnMenu: true,
        width: 250,
        valueGetter: (params: GridValueGetterParams) => {
            if (params.row.email == null) {
            return "-";
            }
            return `${params.row.email}`;
        },
        },
        {
        field: "contact",
        headerName: "Contact",
        sortable: false,
        disableColumnMenu: true,
        width: 150,
        valueGetter: (params: GridValueGetterParams) => {
            if (params.row.contact == null) {
            return "-";
            }
            return `${params.row.contact}`;
        }
        }
    ];

    const columnsWithAction = [
        ...columns,
        {
        field: "activate",
        headerName: "Activation",
        sortable: false,
        disableColumnMenu: true,
        width: 140,
        renderCell: (params: any) => {

            return (
            <>
                {<Button
                variant="contained"
                color={params.row.status === 1 ? "warning" :"success"}
                //disabled={editable || importCostingAllShipment.loading}
                onClick={() => {
                    handleCreateVendorBranch({
                        id: params.row.id,
                        status: params.row.status === 1 ? 0 : 1
                    })
                }}
                >
                    {params.row.status === 1 ? `Deactivate` : `Activate`}
                </Button>
                }
            </>
            );
        },
        },
        {
        field: "edit",
        headerName: "Edit",
        sortable: false,
        disableColumnMenu: true,
        width: 140,
        renderCell: (params: any) => (
            <Button
            variant="contained"
            color="primary"
            //disabled={importCostingAllShipment.loading}
            onClick={() => {
                // setEditRequest(true);
                // setSelectedShipment(params.row.id);
                setValue({
                    id: params.row.id,
                    branch_name: params.row.branch_name,
                    description: params.row.description,
                    email: params.row.email,
                    duration: params.row.duration,
                    vendor_id: vendorId,
                    vendor: { id: vendorId, vendor_name: ""},
                    status: 1
                })
            }}
            >
            Edit
            </Button>
        ),
        },
        // {
        // field: "branches",
        // headerName: "Branches",
        // sortable: false,
        // disableColumnMenu: true,
        // width: 140,
        // renderCell: (params: any) => (
        //     <Button
        //     variant="contained"
        //     color="secondary"
        //     //   disabled={importCostingAllShipment.loading}
        //     onClick={() => {
        //         // setSelectedShipment(params.row.id);
        //         // setIsViewForm(true);
        //         // setUpdateForm(true);
        //     }}
        //     >
        //     View Branches
        //     </Button>
        // ),
        // },
    ];

    useEffect(()=>{
        dispatch(findBranchedByVendor({id: vendorId, page: 1}));
    },[])

    useEffect(()=>{
        dispatch(findBranchedByVendor({id: vendorId, page: paginationModel.page}));
    },[paginationModel]);

    useEffect(()=>{
        dispatch(findBranchedByVendor({id: vendorId, page: 1}));
    },[vendor.createBranchResponse]);

  return (
    <>
        <Grid item lg={4} md={6} sm={8} xs={12} display={"flex"}>
            <FieldContainer label="" sx={{marginLeft: 3}}>
                <Button variant="contained"
                startIcon={<ArrowBackIcon />}
                onClick={()=>navigate(MANAGE_VENDOR_ROUTE)}>
                    Back to Vendors
                </Button>
            </FieldContainer>
        </Grid>
        <Box className="form" sx={{ width: "500px", marginLeft: "25px"}}>
        <FormContainer className="form" formTitle="Branch Details" >
            <Formik
                enableReinitialize={true}
                initialValues={value}
                validationSchema={branchSchema}
                onSubmit={handleCreateVendorBranch}
            >
                {(props) => (
                <Form>
                    <FieldContainer label="Branch name*" sx={{marginBottom:3}}>
                        <TextField
                            name="branch_name"
                            size="small"
                            placeholder={""}
                            fullWidth
                            value={props.values.branch_name}
                            error={props.errors.branch_name === undefined ? false : true}
                            helperText={props.errors.branch_name}
                            onChange={(e) => {
                                props.setFieldValue(e.target.name, e.target.value);
                            }}
                        />
                    </FieldContainer>
                    <FieldContainer label="Email" sx={{marginBottom:3}}>
                        <TextField
                            name="email"
                            size="small"
                            placeholder={""}
                            fullWidth
                            value={props.values.email}
                            error={props.errors.email === undefined ? false : true}
                            helperText={props.errors.email}
                            onChange={(e) => {
                                props.setFieldValue(e.target.name, e.target.value);
                            }}
                        />
                    </FieldContainer>
                    <FieldContainer label="Description" sx={{marginBottom:3}}>
                        <TextField
                            name="description"
                            size="small"
                            placeholder={""}
                            fullWidth
                            value={props.values.description}
                            error={props.errors.description === undefined ? false : true}
                            helperText={props.errors.description}
                            onChange={(e) => {
                                props.setFieldValue(e.target.name, e.target.value);
                            }}
                        />
                    </FieldContainer>
                    <FieldContainer label="Duration" sx={{marginBottom:3}}>
                        <TextField
                            name="duration"
                            size="small"
                            placeholder={""}
                            fullWidth
                            value={props.values.duration}
                            error={props.errors.duration === undefined ? false : true}
                            helperText={props.errors.duration}
                            onChange={(e) => {
                                props.setFieldValue(e.target.name, e.target.value);
                            }}
                        />
                    </FieldContainer>
                    <Button sx={{background:"#8C3C99", width:"200px", marginRight:"20px"}} variant="contained" type="submit" >
                      Save Branch
                    </Button>
                    <Button onClick={() =>setValue({...value, ...defaultVendorBranch, vendor_id: vendorId})} sx={{background:"#fff", color:"#8C3C99", '&:hover': {
                        color: '#fff',
                      }, width:"200px", border:"1px solid #8C3C99"}} 
                      variant="contained">
                      Reset
                    </Button>
                </Form> 
                )}
            </Formik> 
        </FormContainer>
        </Box>

        <FormContainer
            formTitle="Manage Branches"
            sx={{ width: "1300px", border: "none" }}
        >
            <FieldContainer label="">
            {vendor.createBranchError && <AlertBox message={vendor.createBranchError.response.data.error} severity="error" showAlert /> } 
            </FieldContainer>
            <FieldContainer label="">
            {vendor.createBranchResponse && <AlertBox message={vendor.createBranchResponse.message} severity="success" showAlert /> } 
            </FieldContainer>
            <DataTable
                columns={columnsWithAction}
                loading={vendor.branchResponseLoading}
                rows={vendor.branchResponse?.data || []}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                //hidePagination={true}
            />
        </FormContainer>
    </>
  );
};

export default ManageBranches;
