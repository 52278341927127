import { Box, Button, Grid, Typography } from "@mui/material";
import logo from "../../assets/logo-white-bg.png";
import CustomCard from "../CustomCard/custom-card";
import { useNavigate } from "react-router-dom";
import { ADMIN_LOGIN_ROUTE, LOGIN_ROUTE, MAIN_ROUTE, SIGNUP_CUSTOMER_ROUTE, SIGNUP_ROUTE } from "../../constants/routes";
import CustomerType  from '../../assets/customertype.png';
import VendorType from '../../assets/vendortype.png';

const imgBaseUrl = process.env.REACT_APP_IMG_BASE_URL;

const Main = () => {
    const navigate = useNavigate();
    
  const handleOnClick = (type: string) => {
    if(type === "customer"){
      navigate(LOGIN_ROUTE);
    }else{
      navigate(LOGIN_ROUTE);
    }
  };

  return (
    <Box
      className="boxstly"
      sx={{
        display: "flex",
        marginLeft: "10%",
        alignContent: "center",
        width: "80%",
        height: "auto",
      }}
    >
      <Box className='form' sx={{ width: '500px', margin: 'auto' }}>
        <Box display={'flex'} justifyContent={'flex-end'}>
          <Button sx={{background:'#cf9dd7', width:'200px', border: '3px solid #8C3C99'}} 
            variant="contained" 
            onClick={() => navigate(ADMIN_LOGIN_ROUTE)}
          >
              Admin Login
          </Button>
        </Box>
        <div>
          <img className="logo" src={logo} width={50} onClick={() => navigate(MAIN_ROUTE)}/>
        </div>
        <Typography variant="h5" sx={{ color: "#8C3C99", fontWeight: "700" }}>
          Queue Management System
        </Typography>
        <Button sx={{background:"#8C3C99", width:"400px", marginRight:"20px", borderRadius:"50px"}} variant="contained">
            Learn More About Queue System
        </Button>
        <Typography sx={{ color: "#8C3C99", fontWeight: "700", mt: 4, mb:2 }}>
          Choose an Account Type
        </Typography>

        <Box display={"flex"}>
            <CustomCard handleOnClick={() => handleOnClick("customer")}>
                <Grid container justifyContent={"center"}>
                    <Grid item xs={12} className="heroSectionImgContainer">
                    <img
                        src={`${CustomerType}`}
                        alt=""
                        className="heroSectionImg"
                    />
                    </Grid>
                    <Grid item>{`As a Customer`}</Grid>
                </Grid>
            </CustomCard>
            <CustomCard handleOnClick={() => handleOnClick("vendor")}>
                <Grid container justifyContent={"center"}>
                    <Grid item xs={12} className="heroSectionImgContainer">
                    <img
                        src={`${VendorType}`}
                        alt=""
                        className="heroSectionImg"
                    />
                    </Grid>
                    <Grid item>{`As a Vendor`}</Grid>
                </Grid>
            </CustomCard>
      </Box>
      </Box>
      
      <Box
        className="purple"
        sx={{ backgroundColor: "#CF9DD7", width: "650px", overflow: "hidden" }}
      >
        <div className="image">
          <img className="banner" src={require("../../assets/queue1.png")} />
        </div>
      </Box>
    </Box>
  );
};

export default Main;
